<template>
  <div class="">
    <am-list-page
      title="Trash Store"
      :columns="columns"
      :rows="trashFiles"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
      enable-multi-select
      @onRowSelect="handleRowSelect"
    >
      <template slot="right-extra-header-actions">
        <div
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -10px;"
        >
          <am-button
            icon="TrashIcon"
            label="Remove"
            tool-tip-text="Permanently remove files from trash store"
            variant="outline-danger"
            class="mr-1"
            @click="deleteFiles()"
          />
          <am-button
            icon="RefreshCcwIcon"
            label="Restore"
            tool-tip-text="Restore files"
            @click="restoreFiles()"
          />
        </div>
      </template>
      <template #name="{row}">
        <div class="d-flex align-items-center">
          <font-awesome-icon
            :icon="getFileIcon(row.item)"
            size="2x"
            :style="{ color: getFileColor(row.item) }"
            style="margin-right: 5px;"
          />
          <span>{{ row.item.name }}</span>
        </div>
      </template>
      <template #total_files="{row}">
        <b-badge
          v-if="row.item.type!=='Folder'"
          variant="light-primary"
        >
          N/A
        </b-badge>
        <div v-else>
          <b>{{ row.item.total_files }}</b>
        </div>
      </template>
      <template #folder_name="{row}">
        <div
          v-if="row.item.folder_name"
          class="d-flex align-items-center"
        >
          <font-awesome-icon
            icon="folder"
            size="2x"
            :style="{ color: '#ffda6c' }"
            style="margin-right: 5px;"
          />
          <span>{{ row.item.folder_name }}</span>
        </div>
        <b-badge
          v-else
          variant="light-primary"
        >
          N/A
        </b-badge>
      </template>
    </am-list-page>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmListPage from '@/farmbusiness/components/AmListPage.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import confirm from '@/farmbusiness/mixins/confirm'

export default {
  name: 'Index',
  components: {
    AmListPage,
    AmButton,
    BBadge,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      tableLoading: false,
      trashFilesModalOpened: false,
      selectedTrashFileId: null,
      selectedFiles: [],
      columns: [
        {
          label: '',
          key: 'multiselect',
        },
        {
          label: 'Name',
          key: 'name',
        },
        {
          label: 'Size(MB)',
          key: 'size',
        },
        {
          label: 'Total Files',
          key: 'total_files',
        },
        {
          label: 'Folder',
          key: 'folder_name',
        },
        {
          label: 'Date Deleted',
          key: 'date_deleted',
        },
      ],
    }
  },
  computed: {
    trashFiles() {
      return this.$store.getters['documents/trashFiles']
    },
  },
  mounted() {
    this.$store.dispatch('documents/getTrashFiles')
  },
  methods: {
    handleRowSelect(e) {
      this.selectedFiles = e
    },
    deleteFiles() {
      if (this.selectedFiles.length > 0) {
        this.confirmAction('Are you sure you want to permanently delete selected files?').then(re => {
          if (re) {
            this.tableLoading = true
            this.$store.dispatch('documents/deleteFromTrash', this.selectedFiles.map(it => it.id))
              .then(() => {
                this.showSuccess('Files deleted successfully')
              }).catch(() => {
                this.showError('Failed to delete files')
              })
              .finally(() => {
                this.tableLoading = false
              })
          }
        })
      } else {
        this.showInfo('Select at least one file')
      }
    },
    restoreFiles() {
      if (this.selectedFiles.length > 0) {
        this.confirmAction('Are you sure you want to restore selected files?').then(re => {
          if (re) {
            this.tableLoading = true
            this.$store.dispatch('documents/restoreFiles', this.selectedFiles.map(it => it.id))
              .then(() => {
                this.showSuccess('Restored files successfully')
              }).catch(() => {
                this.showError('Failed to restore files')
              })
              .finally(() => {
                this.tableLoading = false
              })
          }
        })
      } else {
        this.showInfo('Select at least one file')
      }
    },
    getFileColor(item) {
      if (item.type === 'Docx') {
        return '#3a8bfc'
      }
      if (item.type === 'Ppt') {
        return '#d96207'
      }
      if (item.type === 'Excel') {
        return '#017d20'
      }
      if (item.type === 'Pdf') {
        return '#f50c0c'
      }
      if (item.type === 'Folder') {
        return '#ffda6c'
      }
      return null
    },
    getFileIcon(item) {
      if (item.type === 'Docx') {
        return 'file-word'
      }
      if (item.type === 'Ppt') {
        return 'file-powerpoint'
      }
      if (item.type === 'Excel') {
        return 'file-excel'
      }
      if (item.type === 'Pdf') {
        return 'file-pdf'
      }
      if (item.type === 'Folder') {
        return 'folder'
      }
      return null
    },
  },
}
</script>

<style scoped>

</style>
